@import url(https://fonts.googleapis.com/css?family=Bangers:regular);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alt-font {
  font-family: 'Bangers';
}

.App {
  text-align: center;
  justify-content: space-around;
  align-items: center;
  background: rgb(255, 241, 215);
  background-size: cover;
  position: absolute;
  overflow: auto;
  color: rgb(160, 86, 17);
}

.App,
header,
main,
#regular-article.open .text-blocks,
#fold-article-button {
  display: flex;
  flex-direction: column;
}

.App,
header,
#regular-article .article-image-wrapper img,
.article-image-wrapper::before {
  height: 100%;
  width: 100%;
}

header>h1 {
  letter-spacing: 0.1rem;
  box-shadow: 7px 7px black;
  border-right: 15px solid rgb(173, 84, 0);
  border-bottom: 15px solid rgb(173, 84, 0);
  border-left: 2px solid rgb(173, 84, 0);
  border-top: 2px solid rgb(173, 84, 0);
  border-left: 2px solid rgb(173, 84, 0);
  background-color: rgba(255, 255, 255, 0.418);
  width: 30vw;
  min-width: 250px;
  padding: 1rem;
  clip-path: polygon(0% 0%, 97.5% 0%, 100% 18%, 100% 100%, 6% 100%, 0% 86%);
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(1.2);
  text-wrap: nowrap;
  font-size: x-large;

}

header {
  justify-content: space-around;
  align-items: center;
}

#socials {
  width: 30vw;
  min-width: 190px;
  height: 10%;
  margin: auto;
  transform: translateY(100%);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem;
  position: relative;
}

#socials a {
  width: 33%;
  max-width: 90px;
  height: -moz-fit-content;
  height: fit-content;
}

#socials a img {
  opacity: 0.7;
  transition: 0.3s all ease;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

#socials img:hover {
  opacity: 1;
}

.article {
  border-right: 10px solid rgb(119, 57, 0);
  border-bottom: 15px solid rgb(173, 84, 0);
  border-left: 2px solid rgb(173, 84, 0);
  border-top: 2px solid rgb(173, 84, 0);
  border-left: 2px solid rgb(173, 84, 0);
  color: rgb(160, 86, 17);
  background-color: rgba(255, 255, 255, 0.418);
  width: 75vw;
  padding: 2rem;
  clip-path: polygon(0% 0%, 99.2% 0%, 100% 1.4rem, 100% 100%, 2% 100%, 0% calc(100% - 0.7rem));
  text-align: justify;
  overflow: hidden;
}

/* to fix:
  - snapping behaviour
*/
/* .article {
  scroll-snap-align: start;
  scroll-margin-top: 2rem;
} 
.App {
  scroll-snap-type: y mandatory;
}

main {
  scroll-snap-align: start;
  scroll-padding-top: 2rem;
  scroll-margin: 2rem;
}
*/


article {
  margin: 2rem auto;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 15rem;

}

main {
  position: absolute;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  top: 150%;
  margin: auto;
  /* margin-top: 30% !important; */
}

.spaced-letters {
  letter-spacing: 0.35rem;
  font-weight: 800;
}

#regular-article {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#regular-article.closed .text-blocks {
  width: 70%;
  padding: 0rem 1rem;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 0.2fr 0.5fr 2fr auto;
  align-items: start;
  margin-top: 1rem;
}

#regular-article.open .text-blocks {
  width: 70%;
  padding: 0rem 1rem;
  box-sizing: border-box;
  margin-top: 1rem;
}

.text-blocks>p :first-of-type {
  margin: 0;
  line-height: 0rem;
  font-size: smaller;

}

.text-blocks>p :nth-of-type(2) {
  margin: 0;
}

h2 {
  line-height: 0rem;
  margin: 0;
  margin-block: 0;
}

#regular-article .article-image-wrapper {
  width: 25%;
  border-radius: 0.5rem;
  border: inset 5px rgb(210, 103, 2);
  overflow: hidden;
  z-index: 10;
  position: relative;
  box-shadow: 0px 0px 3px 1px black;
}

#regular-article .article-image-wrapper img {
  display: block;
  object-fit: cover;
  object-position: top;
  position: absolute;
}

.article-image-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0px 0 15px 10px rgb(0, 0, 0);
  z-index: 5;
}

button {
  color: inherit;
  margin: 0.5rem 1rem;
  background-color: rgba(245, 222, 179, 0.343);
  padding: 1rem 2rem;
  font-weight: 600;
  border: none;
  border: 1px solid rgb(146, 70, 0) !important;
  box-sizing: border-box;
  border-radius: 0.2rem;
  transition: 0.2s ease all;
}

button:hover {
  background-color: rgb(169, 82, 0);
  color: white;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.59);
  cursor: pointer;
}

.letter-space-1 {
  letter-spacing: 0.2rem;
}

.external-links-wrapper {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.external-links,
.external-links:visited {
  background-color: rgb(169, 82, 0);
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  margin: 0.1rem;
  transform: skew(-12deg);
  border-bottom: 2px solid orange;
  border-right: 2px solid orange;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  font-weight: 600;
  transition: 0.2s ease all;
  box-sizing: content-box;
  max-width: 95%;
  word-wrap: break-word;

}

.external-links:hover {
  border-top: 2px solid orange;
  border-left: 2px solid orange;
  border-bottom: 2px solid rgb(118, 77, 0);
  border-right: 2px solid rgb(118, 77, 0);
  border-radius: 0.6rem;

}




@media screen and (max-width: 850px) {
  .share-icon {
    width: 70%;
    opacity: 0.6;
    transform: translateY(0%) !important;
  }

  #regular-article {
    flex-direction: column;
    width: 75%;
  }

  section {
    width: 100%;

  }

  #regular-article.closed .text-blocks,
  #regular-article.open .text-blocks {
    width: 100%;
    padding: 0;
  }

  h2 {
    font-size: large;
  }

  .external-links {
    font-size: x-small;
  }

  #regular-article .article-image-wrapper {
    width: 100%;
    height: 10rem;
    position: relative;
  }

  #socials {
    transform: translateY(200%);
    min-width: 250px;
    width: 80%;
    max-width: 350px;
  }

  #regular-article .article-image-wrapper img {
    display: block;
    object-fit: cover;
    object-position: 50% 60%;
    position: absolute;
  }
}

#fold-article-button {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(119, 58, 0, 0.815);
  box-shadow: 1px 1px 0px 0px rgba(255, 166, 0, 0.82);
  border: 2px solid rgba(255, 166, 0, 0.862);
  font-size: 2.5rem;
  z-index: 10;
  color: rgba(255, 166, 0, 0.817);
  opacity: 0.8;
  cursor: pointer;
  transform: translate(-5%, -5%);
  box-sizing: content-box;

}

#fold-article-button:hover {
  opacity: 1;
}

#fold-article-button:active {
  box-shadow: 0px 0px 2px 0px black;
  transform: scale(0.95) translate(0%, 0%);

}

.share-icon {
  width: 70%;
  opacity: 0.6;
  transform: translateY(-30%);
}

.share-icon:hover {
  opacity: 1;
  cursor: pointer;
}

.notification-wrapper {
  position: fixed;
  top: 5rem;
  width: 80%;
  padding: 2rem 3rem;
  margin: auto;
  background-color: rgba(195, 111, 0, 0.916);
  color: white;
  font-weight: 600;
  font-family: inherit;
  z-index: 1000;
  display: block;
  border-radius: 1rem;
  transition: 1s ease;
  opacity: 1;
}

.fade {
  opacity: 0;
}

#featured-wrapper {
  width: 84%;
  display: flex;
  flex-direction: row;
  height: 30%;
  max-height: 400px;
  position: absolute;
  top: 110vh;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.featured-card {
  max-width: 500px;
  width: 28.5%;
  height: 100%;
  outline: 3px solid rgb(198, 96, 0);

  background-size: cover;
  outline-offset: 0.5rem;
  /* transform: skew(-12deg); */
  box-sizing: border-box;
  position: relative;
  color: white;
  font-size: 2.2rem;
  font-family: "Bangers";
  border: 4px rgb(244, 244, 244) solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.15s ease-in-out all;
  padding: 0.4rem;
  cursor: pointer;
  margin-top: 1.5rem;

}

#featured-h2 {
  text-align: center;
  font-family: "Bangers";
  font-size: 3rem;
  color: white;
  filter: drop-shadow(0 0 0.2rem rgb(173, 84, 0));
  font-style: italic;
}

.featured-card:hover {
  outline-offset: 0.3rem;
  outline: 5px solid rgb(198, 96, 0);
  transform: scale(1.02);
}
.featured-card p {
  background-color: rgba(0, 0, 0, 0.51);
  height: 100%;
  width: 100%;
display: flex;
align-items: center;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  padding: 1rem;
}


@media screen and (max-width: 950px) {
  #featured-wrapper {
    flex-direction: column;
    height: 10%;
  }
  .featured-card {
    width: 100%;
    max-width: unset;
  }
  .featured-card p {
  width: 100%;
  }
  h2 {
    font-size: medium;
    word-wrap:break-word;
  }

}

@media screen and (max-width: 750px) {
  .featured-card p {
font-size: 1.2rem;
  }
}
